export default {
  data: [
    { date: '2017-09-11', adjusted_grade: '6.8284', grade_rp: '6.9333', grade_fl: '6.566', grade_os: null },
    { date: '2017-09-18', adjusted_grade: '6.8384', grade_rp: '6.9433', grade_fl: '6.576', grade_os: null },
    { date: '2017-09-25', adjusted_grade: '6.8484', grade_rp: '6.9833', grade_fl: '6.596', grade_os: null },
    { date: '2017-10-02', adjusted_grade: '7.1667', grade_rp: '7.15', grade_fl: '6.625', grade_os: null },
    { date: '2017-10-09', adjusted_grade: '7.4157', grade_rp: '7.399', grade_fl: '6.646', grade_os: null },
    { date: '2017-10-16', adjusted_grade: '7.514', grade_rp: '7.514', grade_fl: '6.646', grade_os: null },
    { date: '2017-10-23', adjusted_grade: '7.531', grade_rp: '7.531', grade_fl: '6.796', grade_os: null },
    { date: '2017-10-30', adjusted_grade: '7.548', grade_rp: '7.548', grade_fl: '6.847', grade_os: null },
    { date: '2017-11-06', adjusted_grade: '7.548', grade_rp: '7.548', grade_fl: '6.968', grade_os: null },
    { date: '2017-11-13', adjusted_grade: '7.5153', grade_rp: '7.498', grade_fl: '6.968', grade_os: null },
    { date: '2017-11-20', adjusted_grade: '7.5153', grade_rp: '7.498', grade_fl: '7.034', grade_os: null },
    { date: '2017-11-27', adjusted_grade: '7.5153', grade_rp: '7.498', grade_fl: '7.017', grade_os: null },
    { date: '2017-12-04', adjusted_grade: '7.5153', grade_rp: '7.498', grade_fl: '7.017', grade_os: null },
    { date: '2017-12-11', adjusted_grade: '7.432', grade_rp: '7.366', grade_fl: '6.984', grade_os: null },
    { date: '2017-12-18', adjusted_grade: '7.3337', grade_rp: '7.217', grade_fl: '6.918', grade_os: null },
    { date: '2017-12-25', adjusted_grade: '7.2667', grade_rp: '7.15', grade_fl: '6.868', grade_os: null },
    { date: '2018-01-01', adjusted_grade: '7.1837', grade_rp: '7.033', grade_fl: '6.868', grade_os: null },
    { date: '2018-01-08', adjusted_grade: '7.2337', grade_rp: '7.167', grade_fl: '6.833', grade_os: null },
    { date: '2018-01-15', adjusted_grade: '7.2337', grade_rp: '7.167', grade_fl: '6.833', grade_os: null },
    { date: '2018-01-22', adjusted_grade: '7.183', grade_rp: '7.133', grade_fl: '6.75', grade_os: null },
    { date: '2018-01-29', adjusted_grade: '7.25', grade_rp: '7.2', grade_fl: '6.75', grade_os: null },
    { date: '2018-02-05', adjusted_grade: '7.3', grade_rp: '7.25', grade_fl: '6.75', grade_os: null },
    { date: '2018-02-12', adjusted_grade: '7.4173', grade_rp: '7.367', grade_fl: '6.817', grade_os: null },
    { date: '2018-02-19', adjusted_grade: '7.4337', grade_rp: '7.4', grade_fl: '6.867', grade_os: null },
    { date: '2018-02-26', adjusted_grade: '7.4337', grade_rp: '7.4', grade_fl: '6.883', grade_os: null },
    { date: '2018-03-05', adjusted_grade: '7.367', grade_rp: '7.3', grade_fl: '6.834', grade_os: null },
    { date: '2018-03-12', adjusted_grade: '7.3837', grade_rp: '7.35', grade_fl: '6.916', grade_os: null },
    { date: '2018-03-19', adjusted_grade: '7.3837', grade_rp: '7.35', grade_fl: '6.883', grade_os: null },
    { date: '2018-03-26', adjusted_grade: '7.3333', grade_rp: '7.283', grade_fl: '6.883', grade_os: null },
    { date: '2018-04-02', adjusted_grade: '7.3987', grade_rp: '7.398', grade_fl: '6.883', grade_os: null },
    { date: '2018-04-09', adjusted_grade: '7.366', grade_rp: '7.366', grade_fl: '6.833', grade_os: null },
    { date: '2018-04-16', adjusted_grade: '7.2993', grade_rp: '7.266', grade_fl: '6.817', grade_os: null },
    { date: '2018-04-23', adjusted_grade: '7.2993', grade_rp: '7.266', grade_fl: '6.801', grade_os: null },
    { date: '2018-04-30', adjusted_grade: '7.2993', grade_rp: '7.266', grade_fl: '6.801', grade_os: null },
    { date: '2018-05-07', adjusted_grade: '7.2993', grade_rp: '7.266', grade_fl: '6.801', grade_os: null },
    { date: '2018-05-14', adjusted_grade: '7.1493', grade_rp: '7.115', grade_fl: '6.550', grade_os: null },
    { date: '2018-05-21', adjusted_grade: '7.2497', grade_rp: '7.166', grade_fl: '6.517', grade_os: null },
    { date: '2018-05-28', adjusted_grade: '7.2497', grade_rp: '7.166', grade_fl: '6.583', grade_os: null },
    { date: '2018-06-04', adjusted_grade: '7.0004', grade_rp: '6.899', grade_fl: '6.593', grade_os: null },
    { date: '2018-06-11', adjusted_grade: '7.1503', grade_rp: '7.1', grade_fl: '6.65', grade_os: null },
    { date: '2018-06-18', adjusted_grade: '7.4343', grade_rp: '7.334', grade_fl: '6.784', grade_os: null },
    { date: '2018-06-25', adjusted_grade: '7.4843', grade_rp: '7.384', grade_fl: '6.867', grade_os: null },
    { date: '2018-07-02', adjusted_grade: '7.5507', grade_rp: '7.4', grade_fl: '6.867', grade_os: null },
    { date: '2018-07-09', adjusted_grade: '7.5513', grade_rp: '7.5', grade_fl: '6.966', grade_os: null },
    { date: '2018-07-16', adjusted_grade: '7.5463', grade_rp: '7.5', grade_fl: '6.966', grade_os: null },
    { date: '2018-07-23', adjusted_grade: '7.5413', grade_rp: '7.45', grade_fl: '6.917', grade_os: null },
    { date: '2018-07-30', adjusted_grade: '7.5213', grade_rp: '7.4', grade_fl: '6.917', grade_os: null },
    { date: '2018-08-06', adjusted_grade: '7.5003', grade_rp: '7.383', grade_fl: '6.901', grade_os: null },
    { date: '2018-08-13', adjusted_grade: '7.417', grade_rp: '7.266', grade_fl: '6.901', grade_os: null },
    { date: '2018-08-20', adjusted_grade: '7.333', grade_rp: '7.249', grade_fl: '6.851', grade_os: null },
    { date: '2018-08-27', adjusted_grade: '7.233', grade_rp: '7.132', grade_fl: '6.785', grade_os: null },
    { date: '2018-09-03', adjusted_grade: '7.217', grade_rp: '7.099', grade_fl: '6.851', grade_os: null },
  ],
  labels: [
    { key: 'adjusted_grade', label: 'Combined' },
    { key: 'grade_rp', label: 'Red-Point' },
    { key: 'grade_fl', label: 'Flash' },
    { key: 'grade_os', label: 'On-sight' },
  ],
  pagination: {
    offset: 0,
    max_offset: 0,
  },
}
